import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBHX9UhJuQGTYSSI2Fxiq4Q262woVau9bE",
  authDomain: "razz-apps.firebaseapp.com",
  projectId: "razz-apps",
  storageBucket: "razz-apps.appspot.com",
  messagingSenderId: "455090021042",
  appId: "1:455090021042:web:3f5376a2c6c3d4b8ac9fab"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
