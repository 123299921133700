import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { RoutedMenuItem } from './models/menu-item';

type Props = {
    pages: RoutedMenuItem[],
    currentPath: string
};

const Router = ({ pages, currentPath }: Props) => {
    const CurrentPage = useMemo(() => {
        return pages.find(page => page.routes.includes(currentPath))?.component;
    }, [pages, currentPath]);

    if (!CurrentPage) {
        return <Typography>The URL entered was not found. Please select an option from the menu above.</Typography>
    }

    return <CurrentPage />;
};

export default Router;