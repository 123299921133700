import { RoutedMenuItem } from '../models/menu-item';
import About from './About';
import Home from './Home';

export const pages: RoutedMenuItem[] = [{
    name: 'Home',
    link: '/',
    routes: ['/', 'home'],
    tooltip: 'Home page',
    component: Home
}, {
    name: 'About',
    link: '/about',
    routes: ['/about'],
    tooltip: 'About Razz',
    component: About
}];