import { AppBar, Box, Button, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Tooltip, Typography } from "@mui/material";
import { MenuItem } from './models/menu-item';
import { pages } from './pages';
import Router from './Router';

type Props = {
    menuItems: MenuItem[]
};

const drawerWidth = 240;

const Navigation = ({ menuItems }: Props) => {
    return (
    <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} component="nav">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Typography href="/" variant="h6" component="a" sx={{ flexGrow: 1, color: 'inherit', textDecoration: 'none' }}>
                Razz's Applications
                </Typography>
                <Box>
                    {
                        pages.map(page => (
                            <Button key={page.name} href={page.link} sx={{ color: 'inherit' }}>
                                    {page.name}
                            </Button>
                        ))
                    }
                </Box>
            </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
              }}
        >
            <Toolbar />
            <List>
            {menuItems.map(item => (
                <ListItem divider disablePadding key={item.name} sx={{ justifyContent: 'center' }}>
                    <Tooltip title={item.tooltip || item.name} placement="right">
                        <ListItemButton href={item.link} sx={{ justifyContent: 'center' }}>
                            <ListItemText inset primary={item.name} />
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            ))}
            </List>
        </Drawer>
        <Box component="main">
            <Toolbar />
            <Router pages={pages} currentPath={window.location.pathname} />
        </Box>
    </Box>
    );
};

export default Navigation;