import Navigation from "./Navigation";
import { db } from './firebase.config';
import { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { getDocs, collection } from 'firebase/firestore';
import { MenuItem } from './models/menu-item';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    getDocs(collection(db, "applications/home-page/menu-items")).then(querySnapshot => 
      querySnapshot.docs.map(doc => doc.data() as MenuItem)
    ).then(setMenuItems).finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  } else if (!menuItems) {
    return <Typography>There was an issue loading the applications. Please try again later.</Typography>
  }
  return (
    <Navigation menuItems={menuItems} />
  );
}

export default App;
