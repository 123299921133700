import { deepPurple, indigo } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const themeConfig = {
    palette: {
        primary: deepPurple,
        secondary: indigo
    }
};

export const theme = createTheme(themeConfig);